<template>
  <v-btn class="text-none text-body-1 font-weight-medium"
         color="secondary"
         dark
         large
         rounded>
    ¡Comienza hoy tu prueba Gratis!
  </v-btn>
</template>

<script>
export default {
  name: "RegisterHome",
}
</script>

<style scoped>
</style>
